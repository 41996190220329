<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="data-object-page-title" class="mb-1">
      <button class="btn btn-secondary-dark float-right"
              @click="filterOpened = !filterOpened"
              qid="users-filter-button"
      >
        <font-awesome-icon icon="filter"/>
      </button>
      <UserInviteModal
            v-if="isGranted(permissions.USER_CREATE)"
            @reloadUserList="reloadUserList"
      ></UserInviteModal>
      <span class="btn btn-secondary float-right"
            qid="users-merge-button"
            v-if="isGranted(permissions.USER_CREATE)"
            @click="showMergeModal()"
      >
        {{ $t('maintenance.users.merge_button') }}
      </span>
      <b-form-input
          type="text"
          v-model="filters.keywordValue"
          class="float-right mw-300 mr-3 search-bar"
          v-on:input="debounceInput"
          :placeholder="$t('maintenance.users.search_placeholder')"
          qid="users-search-input"
      ></b-form-input>
      {{$t('maintenance.users.title')}}
    </h4>
    <div>
      <span v-if="getGuide('users_and_owners_list')">
        <GuideButton :guide="getGuide('users_and_owners_list')"></GuideButton>
      </span>
    </div>

    <div class="row mt-4" :class="{'table-with-filters': filterOpened}">
      <div class="col-sm-10 table-container">
        <div class="">
          <table class="table table-responsive-md table-list">
            <thead>
              <tr>
                <th scope="col" class="cursor-pointer"></th>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('name')"
                    qid="users-sort-name"
                >
                  {{ $t('maintenance.users.table.name') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                  </span>
                </th>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('email')"
                    qid="users-sort-email"
                >
                  {{ $t('maintenance.users.table.email') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'email'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'email'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'email'"/>
                  </span>
                </th>
                <th scope="col" qid="users-sort-organization">
                  {{ $t('maintenance.users.table.organization') }}
                </th>
                <th scope="col" class="text-center">
                  {{ $t('maintenance.users.table.authentication') }}
                </th>
                <th scope="col">{{ $t('maintenance.users.table.role') }}</th>
                <th scope="col">{{ $t('maintenance.users.table.dpo') }}</th>
                <th scope="col">{{ $t('maintenance.users.table.locked') }}</th>
                <th width="130"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="object in tableItems">
                <td class="avatar-table-block">
                  <img v-if="object.avatar" v-bind:src="object.avatar"
                       class="rounded-circle"
                       width="50"
                       height="50"
                       qid="profile-photo"
                  >
                  <img v-if="!object.avatar" src="@/assets/avatar.png"
                       class="rounded-circle"
                       width="50"
                       height="50"
                       qid="profile-photo"
                  >
                </td>
                <td><span qid="users-name">{{object.name}}</span></td>
                <td><span qid="users-email">{{object.email}}</span></td>
                <td>
                  <span qid="users-organization" class="badge" v-if="object.organization">
                    <font-awesome-icon icon="sitemap" class="mr-1"/>
                    {{ object.organization.name }}
                  </span>
                </td>
                <td class="text-center">
                  <span v-if="object.authentication == 'Microsoft'" class="fs-1-5">
                    <font-awesome-icon :icon="['fab', 'microsoft']"/>
                  </span>
                  <span v-if="object.authentication == 'Google'" class="fs-1-5">
                    <font-awesome-icon :icon="['fab', 'google']"/>
                  </span>
                  <span v-if="!object.authentication || object.authentication == 'Application'">
                    <img src="@/assets/logo_new.png"
                         class="rounded-circle"
                         width="25"
                         height="25"
                         qid="application-logo"
                    >
                  </span>
                </td>
                <td>
                  <span v-if="getUseRole(object)" qid="users-role">
                    <span class="badge">
                      <font-awesome-icon icon="user-cog" class="mr-1"/>
                      {{ $t('maintenance.users.role.' + $options.filters.slugify(getUseRole(object).name)) }}
                    </span>
                  </span>
                </td>
                <td>
                  <span v-if="object.dpo == '1'">
                    <span class="badge-pill badge-success mr-2" >
                      <font-awesome-icon icon="check" size="xs"/>
                    </span>
                    {{ $t('system.yes') }}
                  </span>
                </td>
                <td>
                  <div v-if="object.locked">
                    <div class="fs-1-5 float-left"
                         @click="lockSwitch(object, 0)"
                         v-if="isGranted([permissions.USER_EDIT, permissions.USER_EDIT_OWN], object)"
                    >
                      <font-awesome-icon class="text-danger cursor-pointer" icon="toggle-on" />
                    </div>
                    <span class="badge">{{ $t('maintenance.users.table.locked') }}</span>
                  </div>
                  <div v-if="!object.locked">
                    <div class="fs-1-5 float-left"
                         @click="lockSwitch(object, 1)"
                         v-if="isGranted([permissions.USER_EDIT, permissions.USER_EDIT_OWN], object)"
                    >
                      <font-awesome-icon class="text-success cursor-pointer fa-rotate-180" icon="toggle-on" />
                    </div>
                    <span class="badge">{{ $t('system.active') }}</span>
                  </div>
                </td>
                <td align="right">
                  <span v-if="isGranted([permissions.USER_EDIT, permissions.USER_EDIT_OWN], object)"
                        class="btn btn-edit"
                        qid="users-edit-button"
                        @click="showEditUserModal(object)"
                  >
                    <font-awesome-icon icon="pen"/>
                  </span>
                  <span v-if="isGranted([permissions.USER_DELETE, permissions.USER_DELETE_OWN], object)"
                        class="btn btn-delete"
                        qid="users-delete-button"
                        @click="showConfirmDeleteModal(object)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                </td>
              </tr>
              <tr v-if="!loading && !tableItems.length" qid="no-results-row" class="text-center">
                <td colspan="10">
                  <h5 class="mt-3">
                    {{ $t('system.no_results') }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3 pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    label="value"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
      <div class="col-sm-2 filter-container">
        <div class="card">
          <div class="card-body">
            <b-form-group>
              <label qid="users-filter-role-label">
                {{ $t('maintenance.users.create.role_label') }}
              </label>
              <multiselect
                  v-model="filters.role"
                  :options="roles"
                  :placeholder="$t('maintenance.users.create.role_placeholder')"
                  label="name_translated"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  @select="debounceInput"
                  @remove="debounceInput"
                  qid="users-filter-role"
              ></multiselect>
            </b-form-group>
            <a href="#"
               @click="resetFilters"
               class="text-right"
               qid="users-filter-reset"
            >
              {{ $t('system.reset_filters') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete user confirm modal template -->
    <b-modal ref="delete-user" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmDeleteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="delete-user-title">
            {{ $t('administration.users.delete.title') }}
          </h5>
        </div>
      </template>
      <div class="mb-2 mt-3" style="font-size:18px;">
        <div>
          {{ $t('administration.users.delete.description') }}
          <span class="text-bold">
            {{ itemToDelete.name }}
          </span>
        </div>
        <div class="mt-2">
          {{ $t('administration.users.delete.are_you_sure') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideConfirmDeleteModal"
                            qid="delete-user-cancel-button"
                  >
                    {{ $t('administration.users.delete.cancel') }}
                  </b-button>
                  <b-button @click="confirmDeleteUser(itemToDelete)"
                            class="ml-3"
                            variant="danger"
                            qid="delete-user-submit-button"
                            v-if="isGranted([permissions.USER_DELETE, permissions.USER_DELETE_OWN], itemToDelete)"
                  >
                    {{ $t('administration.users.delete.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Edit user modal template -->
    <b-modal ref="edit-user" @hide="hideEditUserModal" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditUserModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-users-title">
            {{ $t('administration.users.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-users-error"
      >
        {{ error }}
      </b-alert>

      <b-form qid="edit-users-form">
        <div class="form-container">
          <div class="mb-3">
            <label qid="edit-user-name-label">
              {{ $t('administration.users.create.name_label') }}
            </label>
            <br>
            <span>
              {{ userForm.name }}
            </span>
            <br>
          </div>

          <div class="mb-3">
            <label qid="edit-user-name-label">
              {{ $t('administration.users.create.email_label') }}
            </label>
            <br>
            <span>
              {{ userForm.email }}
            </span>
            <br>
          </div>

          <div v-if="userForm.phone" class="mb-3">
            <label qid="edit-user-phone-label">
              {{ $t('administration.users.create.phone_label') }}
            </label>
            <br>
            <span>
              {{ userForm.phone }}
            </span>
            <br>
          </div>

          <div class="mb-3">
            <label qid="edit-user-name-label">
              {{ $t('maintenance.users.table.authentication') }}
            </label>
            <br>
            <span class="badge">
              <span v-if="userForm.authentication == 'Microsoft'" class="fs-1-5">
                <font-awesome-icon :icon="['fab', 'microsoft']"/>
              </span>
              <span v-if="userForm.authentication == 'Google'" class="fs-1-5">
                <font-awesome-icon :icon="['fab', 'google']"/>
              </span>
              <span v-if="!userForm.authentication || userForm.authentication == 'Application'">
                <img src="@/assets/logo_new.png"
                     class="rounded-circle"
                     width="25"
                     height="25"
                     qid="application-logo"
                >
              </span>
              {{ userForm.authentication }}
            </span>
            <br>
          </div>

          <b-form-group v-if="isGranted(permissions.USER_EDIT) && userForm.authentication != 'Google'">
            <label qid="create-user-upn-label">
              {{ $t('administration.users.create.upn_email_label') }}
            </label>
            <b-form-input
              qid="edit-user-form-upn"
              type="email"
              v-model="userForm.upn_email"
              :maxLength="255"
              :placeholder="userForm.email"
            ></b-form-input>
          </b-form-group>

          <div class="mb-3">
            <label qid="edit-user-phone-label">
              {{ $t('administration.users.create.organization_label') }}
            </label>
            <OrganizationTreeView
                @updatedCompany="updateSelectedCompany"
                :organization="selectedOrganization"
            ></OrganizationTreeView>
          </div>

          <b-form-group v-if="isGranted(permissions.USER_EDIT)">
            <label qid="create-user-role-label">
              {{ $t('administration.users.create.role_label') }}
            </label>
            <multiselect
                v-model="selectedRole"
                :options="roles"
                track-by="id"
                :placeholder="$t('administration.clients.members.select_role_placeholder')"
                label="name_translated"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @remove="resetRole"
                qid="create-user-form-role"
            ></multiselect>
          </b-form-group>
          <b-alert v-if="selectedRole && (selectedRole.name === 'Client admin' || selectedRole.name === 'Partition admin')"
                   show
                   variant="warning"
                   qid="create-user-error"
          >
             {{ $t('administration.users.create.role_cost_notice') }}
          </b-alert>
          <div class="mw-500">
            <label qid="edit-user-dpo-label">
              {{ $t('administration.users.create.dpo_label') }}
            </label>
            <b-form-radio-group
                    v-model="userForm.dpo"
                    :options="dpoOptions"
                    qid="scope-radio-option"
            ></b-form-radio-group>
          </div>
        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideEditUserModal"
                            qid="edit-user-cancel-button"
                  >
                    {{ $t('administration.users.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            @click="editUserFormSubmit"
                            :disabled="buttonDisabled"
                            qid="edit-user-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('administration.users.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Merge users modal -->
    <b-modal ref="merge-users" @hide="hideMergeModal" modal-class="task-modal" hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideMergeModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('maintenance.users.merge_button') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-user-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="mergeFormSubmit" qid="edit-user-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-user-upn-email-label">
              {{ $t('maintenance.users.from_user_label') }}
            </label>
            <multiselect
                v-model="selectedFirstUser"
                :options="users"
                :multiple="false"
                track-by="slug"
                :placeholder="$t('maintenance.users.from_user_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @remove="updateUsersList"
                @select="updateUsersList"
                @search-change="loadUsers"
                qid="create-p-users-select"
            >
              <template slot="singleLabel" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-user-upn-email-label">
              {{ $t('maintenance.users.to_user_label') }}
            </label>
            <multiselect
                v-model="selectedSecondUser"
                :options="users"
                :multiple="false"
                track-by="slug"
                :placeholder="$t('maintenance.users.to_user_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @remove="updateUsersList"
                @select="updateUsersList"
                @search-change="loadUsers"
                qid="create-p-users-select"
            >
              <template slot="singleLabel" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="keepUser"
              name="checkbox-disable"
              :value="1"
              :unchecked-value="0"
            >
              {{ $t('maintenance.users.keep_user') }}
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideMergeModal"
                            qid="edit-user-cancel-button"
                  >
                    {{ $t('administration.users.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="edit-user-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.users.merge_users_submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import {config} from '@/config.js';
  import OrganizationTreeView from '@/components/organizations/OrganizationTreeView.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';
  import Avatar from '@/components/Avatar.vue';
  import UserInviteModal from '@/components/user/UserInviteModal.vue';

  export default {
    name: 'Users',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.title'),
            to: '/maintenance'
          },
          {
            text: this.$t('maintenance.users.title'),
            active: true
          }
        ],
        tableItems: [],
        selectedFirstUser: [],
        selectedSecondUser: [],
        keepUser: 1,
        users: [],

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,

        itemToDelete: {},
        userForm: {
          slug: '',
          name: '',
          phone: '',
          client: '',
          organization: '',
          role: '',
          authentication: '',
          upn_email: '',
          dpo:'No'
        },
        error: '',
        roles: [],
        selectedRole: [],
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',
        filters: {
          type: '',
          data_processor_agreement: '',
          dpa_updated_at: '',
          countries: [],
          keywordValue: ''
        },
        loading: true,
        buttonDisabled: false,
        selectedOrganization: {},
        myGuides: [],
        dpoOptions: [
          {text: this.$t('system.yes'), value: '1'},
          {text: this.$t('system.no'), value: '0'},
        ],
      }
    },
    components: {
      OrganizationTreeView,
      GuideButton,
      Avatar,
      UserInviteModal
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows;
      },
      getClient() {
        return this.$store.state.client;
      },
      getFilters() {
        return this.$store.state.activeFilters;
      },
      getStorePerPage() {
        return this.$store.state.perPage;
      },
      getClientRoles() {
        return this.$store.state.client.clientRoles;
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf;
        }

        return selectedFrom + " - " + selectedTo + " of " + selectedOf;
      },
      isEmailValid() {
        if (this.name) {
          return this.isValid(this.name);
        }
        return null;
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadContent();
        this.setRoles();
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadContent();
      }, 400),
      updateSelectedCompany(item) {
        this.userForm.organization = item.id;
      },
      updateUsersList(item) {
        this.loadUsers();
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = [];

          _.forEach(response.data.items, function(object) {
            if ((self.selectedFirstUser && self.selectedFirstUser.id && object.id == self.selectedFirstUser.id) || (self.selectedSecondUser && self.selectedSecondUser.id && object.id == self.selectedSecondUser.id)) {
              // do nothing
            } else {
              self.users.push(object);
            }
          })

          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true});
          }
        }, function() {});
      },
      showMergeModal(object) {
        let self = this;
        this.error = '';
        this.loadUsers();
        this.selectedFirstUser = [];
        this.selectedSecondUser = [];
        this.buttonDisabled = false;

        this.$refs['merge-users'].show();
      },
      hideMergeModal() {
        this.$refs['merge-users'].hide();
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Maintenance' && object.enabled;
        });
        return existingGuide;
      },
      resetFilters() {
        this.filters.type = '';
        this.filters.role = '';
        this.debounceInput();
      },
      resetRole() {
        this.selectedRole = []
      },
      lockSwitch(user, type) {
        let self = this;
        piincHttp.patch('users/' + user.slug + '/lock', {lock: type, client: this.$store.state.client.slug}).then(function(response) {
          self.loadContent(self.currentPage);
        }, function(error) {})
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadContent();
      },
      reloadUserList() {
        this.loadContent();
      },
      loadContent(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        if (this.filters.keywordValue !== '' || (this.filters.role && this.filters.role.id)) {
          this.filtersOpened = true;
        }

        piincHttp.get('users', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            sort: this.selectedSort !== '' ? this.selectedSort : undefined,
            order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
            keyword: this.filters.keywordValue !== '' ? this.filters.keywordValue : undefined,
            role: this.filters.role ? this.filters.role.id : undefined,
            client: this.getClient.id
          }
        }).then(function(response) {
          self.loading = false;
          self.tableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;
        }, function() {});
      },
      pageChanged(page) {
        this.loadContent(page);
      },
      perPageChanged(perPage) {
        let self = this;
        // Load content when page changed listener is triggered
        this.loadContent(1, perPage.value);
        self.selectedPerPage = perPage.value;
        this.$store.commit('storePerPage', this.selectedPerPage);
      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value;
        this.loadContent(1, self.selectedPerPage);
      },
      confirmDeleteUser(user) {
        let self = this;
        piincHttp.delete('clients/' + this.$store.state.client.slug + '/users/' + user.slug).then(function() {
          self.hideConfirmDeleteModal();
          self.loadContent(self.currentPage);
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('administration.users.delete.success'));
        }, function() {});
      },
      editUserFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();
        this.userForm.client = this.$store.state.client.slug;
        this.userForm.role = this.selectedRole ? this.selectedRole.id : undefined;
        piincHttp.put('users/' + this.userForm.slug, this.userForm).then(function() {
          self.hideEditUserModal();
          self.buttonDisabled = false;
          self.loadContent(self.currentPage);
          self.$toastr('success', self.$t('administration.users.update.success'));
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      showEditUserModal(object) {
        this.userForm.slug = object.slug;
        this.userForm.name = object.name;
        this.userForm.email = object.email;
        this.userForm.upn_email = object.upn_email;
        this.userForm.phone = object.phone;
        this.userForm.dpo = object.dpo;
        if(object.authentication) {
          this.userForm.authentication = object.authentication;
        }else{
          this.userForm.authentication = 'Application';
        }
        this.selectedOrganization = object.organization ? object.organization : {};
        this.selectedRole = _.cloneDeep(this.getUseRole(object));
        this.selectedRole.name_translated = this.$t('maintenance.users.role.' + this.$options.filters.slugify(this.selectedRole.name));

        this.error = '';
        this.$refs['edit-user'].show();
      },
      hideEditUserModal() {
        this.$refs['edit-user'].hide();
      },
      mergeFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        if (evt) {
          evt.preventDefault();
        }

        if (this.selectedFirstUser && this.selectedFirstUser.id && this.selectedSecondUser && this.selectedSecondUser.id ) {
          piincHttp.post('users/merge', {
            from_user: this.selectedFirstUser.id,
            to_user: this.selectedSecondUser.id,
            client: this.getClient.slug,
            keep_user: this.keepUser
          }).then(function(response) {
            self.$toastr('success', self.$t('maintenance.users.merge_users_success'));
            self.buttonDisabled = false;
            self.loadContent();
            self.hideMergeModal();
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.buttonDisabled = false;
          this.error = self.$t('maintenance.users.merge_users_error');
        }

      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object;
        this.$refs['delete-user'].show();
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {};
        this.$refs['delete-user'].hide();
      },
      getUseRole(user) {
        return _.find(user.userRoles, { 'client': this.$store.state.client.id });
      },
      setRoles() {
        let self = this;
        this.roles = [];

        if (this.getClient && this.getClient.parent) {
          _.forEach(this.getClientRoles, function(role) {
            if (role.name != 'Client admin'){
              self.roles.push(role);
            }
          })
        } else {
          _.forEach(this.getClientRoles, function(role) {
            if (role.name != 'Partition admin'){
              self.roles.push(role);
            }
          })
        }
        _.forEach(this.roles, function(role) {
          role.name_translated = self.$t('maintenance.users.role.' + self.$options.filters.slugify(role.name));
        })
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            scope: code,
            language: 'en',
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides = response.data.items;
          }
        }, function() {});
      }
    },
    beforeRouteLeave(to, from, next) {
      let object = {
        type: 'users',
        filters: this.filters
      }
      this.$store.commit('storeActiveFilters', object);
      this.$store.commit('storePerPage', this.selectedPerPage);
      next()
    },
    created() {
      if (this.getClient && this.getClient.slug) {
        if (this.getFilters.users) {
          this.filters = this.getFilters.users;
        }
        this.loadContent();
      }
      this.setRoles();
      this.loadGuides('users_and_owners_list');
    }
  }
</script>
