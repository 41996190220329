<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="partition-page-title" class="mb-1">
      <!-- <button class="btn btn-secondary-dark float-right"
              @click="filterOpened = !filterOpened"
              qid="partition-filter-button"
      >
        <font-awesome-icon icon="filter"/>
      </button> -->
      <span class="btn btn-success float-right"
            qid="partition-create-button"
            @click="showCreatePartitionModal()"
      >
        <font-awesome-icon icon="plus-circle"/> {{ $t('administration.partitions.create_button') }}
      </span>
      <!-- <router-link
          to="/maintenance/partitions/create"
          class="btn btn-success float-right"
          qid="partition-create-button"
      >
        <font-awesome-icon icon="plus"/> {{ $t('administration.partitions.create_button') }}
      </router-link> -->
      <b-form-input
              type="text"
              v-model="filters.keywordValue"
              class="float-right mw-300 mr-3 search-bar"
              v-on:input="debounceInput"
              :placeholder="$t('maintenance.partitions.search_placeholder')"
              qid="partitions-search-input"
      ></b-form-input>
      {{$t('maintenance.partitions.title')}}
    </h4>
    <div>
      <span v-if="getGuide('partitions_list')">
        <GuideButton :guide="getGuide('partitions_list')"></GuideButton>
      </span>
    </div>


    <div class="row mt-4" :class="{'table-with-filters': filterOpened}">
      <div class="col-sm-10 table-container">
        <div class="">
          <table class="table table-responsive-md table-list">
            <thead>
              <tr>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('name')"
                    qid="sort-p-name"
                >
                  {{ $t('administration.partitions.create.name_label') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                  </span>
                </th>
                <th scope="col">{{ $t('administration.partitions.create.cvr_label') }}</th>
                <th scope="col">{{ $t('administration.partitions.create.country_label') }}</th>
                <th scope="col">{{ $t('partitions.date_created_label') }}</th>

                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('updated_at')"
                    qid="sort-p-date"
                >
                  {{ $t('table.last_updated') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                  </span>
                </th>
                <th width="200"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(object, index) in tableItems" :key="'table-row-' + index" :id="'table-row-' + index">
                <b-popover
                    :target="'table-row-' + index"
                    boundary="viewport"
                    triggers="hover"
                    placement="topleft"
                    :delay="{ show: 500, hide: 0 }"
                >
                  <div v-if="object.street" class="mt-2">
                    <span class="text-bold">
                      {{ $t('administration.partitions.create.street_label') }}:
                    </span>
                    <br>
                    {{ object.street }}
                    <br>
                  </div>
                  <div v-if="object.city" class="mt-2">
                    <span class="text-bold">
                      {{ $t('administration.partitions.create.city_label') }}:
                    </span>
                    <br>
                    {{ object.city }}
                    <br>
                  </div>
                  <div v-if="object.postcode" class="mt-2">
                    <span class="text-bold">
                      {{ $t('administration.partitions.create.postcode_label') }}:
                    </span>
                    <br>
                    {{ object.postcode }}
                    <br>
                  </div>
                  <div v-if="object.country" class="mt-2">
                    <span class="text-bold">
                      {{ $t('administration.partitions.create.country_label') }}:
                    </span>
                    <br>
                    <img v-bind:src="object.country_with_flag.flag" height="13" class="align-baseline mr-1" v-if="object && object.country_with_flag"/>
                    {{ object.country }}
                    <br>
                  </div>
                </b-popover>
                <td>
                  <span qid="partitions-cvr">{{object.name}}</span>
                  <!-- <router-link
                      :to="{ name: 'maintenance.partitionView', params: { slug: object.slug }}"
                      qid="partitions-name"
                  >
                    {{object.name}}
                  </router-link> -->
                </td>
                <td><span qid="partitions-cvr">{{object.cvr}}</span></td>
                <td><span qid="partitions-country">
                  <img v-bind:src="object.country_with_flag.flag" height="13" class="align-baseline mr-1" v-if="object && object.country_with_flag"/>
                  {{object.country}}
                </span></td>
                <td><span qid="partitions-date-created">{{object.created_at | formatDate}}</span></td>
                <td><span qid="partitions-date-updated">{{object.updated_at | formatDate}}</span></td>
                <td align="right">

                    <router-link
                        :to="{ name: 'maintenance.partitionMembers', params: { slug: object.slug }}"
                        qid="partitions-members-button"
                        class="btn btn-view"
                    >
                      <font-awesome-icon icon="users" />
                    </router-link>
                  <span class="btn btn-edit" qid="partitions-edit-button" @click="showEditPartitionModal(object)">
                    <font-awesome-icon icon="pen"/>
                  </span>
                  <span class="btn btn-delete" qid="partitions-delete-button" @click="showConfirmDeleteModal(object)">
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                </td>
              </tr>
              <tr v-if="!loading && !tableItems.length" qid="no-results-row" class="text-center">
                <td colspan="10">
                  <h5 class="mt-3">
                    {{ $t('system.no_results') }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3 pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    label="value"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-2 filter-container">
        <div class="card">
          <div class="card-body">
            <b-form-group>


            </b-form-group>
            <a href="#"
               @click="resetFilters"
               class="text-right"
               qid="partition-filter-reset"
            >
              {{ $t('system.reset_filters') }}
            </a>
          </div>
        </div>
      </div> -->
    </div>


    <!-- Delete client confirm modal template -->
    <b-modal ref="delete-partition" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmDeleteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="delete-partition-title">
            {{ $t('administration.partitions.delete.title') }}
          </h5>
        </div>
      </template>
      <div class="mb-2 mt-3" style="font-size:18px;">
        <div>
          {{ $t('administration.partitions.delete.description') }}
          <span class="text-bold">
            {{ itemToDelete.name }}
          </span>
        </div>
        <div class="mt-2">
          {{ $t('administration.partitions.delete.are_you_sure') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideConfirmDeleteModal"
                            qid="delete-partition-cancel-button"
                  >
                    {{ $t('administration.partitions.delete.cancel') }}
                  </b-button>
                  <b-button @click="confirmDeletePartition(itemToDelete)"
                            class="ml-3"
                            variant="danger"
                            qid="delete-partition-submit-button"
                  >
                    {{ $t('administration.partitions.delete.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Edit partition modal template -->
    <b-modal ref="edit-partition" @hide="hideEditPartitionModal" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditPartitionModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-partitions-title">
            {{ $t('administration.partitions.update.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-partitions-error"
      >
        {{ error }}
      </b-alert>

      <b-form qid="edit-partition-form">
        <div class="form-container">
          <b-form-group>
            <label qid="edit-partition-name-label">
              {{ $t('administration.partitions.create.name_label') }}
            </label>
            <b-form-input
              qid="edit-partition-form-name"
              type="text"
              v-model="clientForm.name"
              :maxLength="255"
              required
              :placeholder="$t('administration.partitions.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="edit-partition-cvr-label">
              {{ $t('administration.partitions.create.cvr_label') }}
            </label>
            <b-form-input
              qid="edit-partition-form-cvr"
              type="text"
              v-model="clientForm.cvr"
              :placeholder="$t('administration.partitions.create.cvr_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="edit-partition-street-label">
              {{ $t('administration.partitions.create.street_label') }}
            </label>
            <b-form-input
              qid="edit-partition-form-street"
              type="text"
              v-model="clientForm.street"
              :placeholder="$t('administration.partitions.create.street_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="edit-partition-city-label">
              {{ $t('administration.partitions.create.city_label') }}
            </label>
            <b-form-input
              qid="edit-partition-form-city"
              type="text"
              v-model="clientForm.city"
              :placeholder="$t('administration.partitions.create.city_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="edit-partition-postcode-label">
              {{ $t('administration.partitions.create.postcode_label') }}
            </label>
            <b-form-input
              qid="edit-partition-form-postcode"
              type="text"
              v-model="clientForm.postcode"
              :placeholder="$t('administration.partitions.create.postcode_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="edit-partition-country-label">
              {{ $t('administration.partitions.create.country_label') }}
            </label>
            <multiselect
                v-model="selectedCountry"
                :options="countries"
                :multiple="false"
                :taggable="false"
                track-by="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('administration.partitions.create.country_label')"
                label="name"
                qid="edit-partition-countries-select"
            >
              <template slot="singleLabel" slot-scope="props">
                <img v-bind:src="props.option.flag" height="13" class="align-baseline mr-1" />
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <img v-bind:src="props.option.flag" height="13" class="align-baseline mr-1" />
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-partition-ref-label">
              {{ $t('administration.partitions.create.reference_label') }}
            </label>
            <b-form-input
              qid="create-partition-form-cvr"
              type="text"
              v-model="clientForm.reference_number"
              :placeholder="$t('administration.partitions.create.reference_label')"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideEditPartitionModal"
                            qid="edit-partition-cancel-button"
                  >
                    {{ $t('administration.partitions.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            @click="editPartitionFormSubmit"
                            :disabled="buttonDisabled"
                            qid="edit-partition-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('administration.partitions.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Create partition modal template -->
    <b-modal ref="create-partition" @hide="hideCreatePartitionModal" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreatePartitionModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-partitions-title">
            {{ $t('administration.partitions.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-partitions-error"
      >
        {{ error }}
      </b-alert>

      <b-form qid="create-partition-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-partition-name-label">
              {{ $t('administration.partitions.create.name_label') }}
            </label>
            <b-form-input
              qid="create-partition-form-name"
              type="text"
              v-model="clientForm.name"
              :maxLength="255"
              required
              :placeholder="$t('administration.partitions.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-partition-cvr-label">
              {{ $t('administration.partitions.create.cvr_label') }}
            </label>
            <b-form-input
              qid="create-partition-form-cvr"
              type="text"
              v-model="clientForm.cvr"
              :placeholder="$t('administration.partitions.create.cvr_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-partition-street-label">
              {{ $t('administration.partitions.create.street_label') }}
            </label>
            <b-form-input
              qid="create-partition-form-street"
              type="text"
              v-model="clientForm.street"
              :placeholder="$t('administration.partitions.create.street_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-partition-city-label">
              {{ $t('administration.partitions.create.city_label') }}
            </label>
            <b-form-input
              qid="create-partition-form-city"
              type="text"
              v-model="clientForm.city"
              :placeholder="$t('administration.partitions.create.city_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-partition-postcode-label">
              {{ $t('administration.partitions.create.postcode_label') }}
            </label>
            <b-form-input
              qid="create-partition-form-postcode"
              type="text"
              v-model="clientForm.postcode"
              :placeholder="$t('administration.partitions.create.postcode_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-partition-country-label">
              {{ $t('administration.partitions.create.country_label') }}
            </label>
            <multiselect
                v-model="selectedCountry"
                :options="countries"
                :multiple="false"
                :taggable="false"
                track-by="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('administration.partitions.create.country_label')"
                label="name"
                qid="create-partition-countries-select"
            >
              <template slot="singleLabel" slot-scope="props">
                <img v-bind:src="props.option.flag" height="13" class="align-baseline mr-1" />
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <img v-bind:src="props.option.flag" height="13" class="align-baseline mr-1" />
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-partition-ref-label">
              {{ $t('administration.partitions.create.reference_label') }}
            </label>
            <b-form-input
              qid="create-partition-form-cvr"
              type="text"
              v-model="clientForm.reference_number"
              :placeholder="$t('administration.partitions.create.reference_label')"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideCreatePartitionModal"
                            qid="create-partition-cancel-button"
                  >
                    {{ $t('administration.partitions.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            @click="createPartitionFormSubmit"
                            :disabled="buttonDisabled"
                            qid="create-partition-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('administration.partitions.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import permissionCheck from '@/util/ACL/permissionCheck.js'
import GuideButton from '@/components/guide/GuideButton.vue';


export default {
  name: 'Partitions',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('maintenance.title'),
          to: '/maintenance'
        },
        {
          text: this.$t('maintenance.partitions.title'),
          active: true
        }
      ],
      tableItems: [],
      selectedSort: '',
      selectedDirection: '',
      itemToDelete: {},
      clientForm: {
        client: '',
        slug: '',
        name: '',
        cvr: '',
        street: '',
        city: '',
        postCode: '',
        country: '',
        reference_number: '',
        country_with_flag: {},
      },
      countries: [],
      selectedCountry: [],
      currentPage: 1,
      totalRows: 0,
      perPage: '',
      selectedPerPage: "10",
      perPageOptions: [
        { name: '10', value: '10' },
        { name: '20', value: '20' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
      ],

      error: '',
      loading: true,
      buttonDisabled: false,
      myGuides: [],
      filters: {
        keywordValue: ''
      },
    }
  },
  components: {
    GuideButton
  },
  computed: {
    rows() {
      // Use computed value for rows because of dynamic update
      return this.totalRows
    },
    getClient() {
      return this.$store.state.client
    },
    getStorePerPage() {
      return this.$store.state.perPage
    },
    getPerPage() {
      let page = this.currentPage;
      let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
      let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
      let selectedOf = this.totalRows;

      if(selectedTo > selectedOf){
        selectedTo = selectedOf
      }

      return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function () {
      this.loadContent();
    }
  },
  methods: {
    debounceInput: _.debounce(function() {
      this.loadContent()
    }, 400),
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Maintenance' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Maintenance',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    loadCountries() {
      let self = this;
      piincHttp.get('countries-with-flag').then(function(response) {
        self.countries = response.data
        self.loadContent();
      }, function() {});
    },
    loadContent(page, perPage) {

      if (this.getStorePerPage) {
        this.selectedPerPage = this.getStorePerPage;
      }

      // If page is not defined, set page to default 1
      let pageNumber = page ? page : 1;
      let perPageNumber = perPage ? perPage : this.selectedPerPage;
      let self = this;
      piincHttp.get('partitions', { params: {
        page: pageNumber,
        per_page: perPageNumber,
        sort: this.selectedSort !== '' ? this.selectedSort : undefined,
        order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
        keyword: this.filters.keywordValue !== '' ? this.filters.keywordValue : undefined,
        client: self.getClient.slug
      }}).then(function(response) {
        let n = 0;
        self.tableItems = response.data.items;
        self.tableItems.forEach(function(partition) {
          if (partition.country) {
            _.find(self.countries, function(country) {
              if (country.name === partition.country) {
                self.tableItems[n].country_with_flag = {};
                self.tableItems[n].country_with_flag.name = country.name;
                self.tableItems[n].country_with_flag.flag = country.flag;
              }
            });
          }
          n += 1;
        })

        // Set number of total items
        self.totalRows = response.data.total_count;

        self.loading = false;
      }, function() {

      });
    },
    selectSort(sort) {
      // If old and new sort are same, switch just direction
      if (sort === this.selectedSort) {
        this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
      } else {
        // If different sort, set sort and set direction to default
        this.selectedDirection = 'desc'
        this.selectedSort = sort;
      }
      this.loadContent();
    },
    pageChanged(page) {
      this.loadContent(page)
    },
    perPageChanged(perPage) {
      let self = this;

      self.selectedPerPage = perPage.value
      this.$store.commit('storePerPage', this.selectedPerPage);
      // Load content when page changed listener is triggered
      this.loadContent(1, perPage.value)
    },
    perPageRemoved() {
      let self = this;
      // Load content when page changed listener is triggered
      self.selectedPerPage = self.perPageOptions[0].value
      this.loadContent(1, self.selectedPerPage)
    },
    isGranted(permission) {
      return permissionCheck(permission)
    },
    confirmDeletePartition(client) {
      let self = this;

      piincHttp.delete('partitions/' + client.slug).then(function(response) {
        piincHttp.get('users/me').then(function(response) {
          self.user = response.data.user;
          self.clients = response.data.clients;
          self.user.clients = self.clients;
          // store logged in user in store
          self.$store.commit('storeUser', self.user);

        }, function() {});
        self.hideConfirmDeleteModal();
        self.loadContent(self.currentPage);
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('administration.partitions.delete.success'))
      }, function() {

      });
    },
    createPartitionFormSubmit(evt) {
      this.buttonDisabled = true;
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      evt.preventDefault();

      if (this.selectedCountry && this.selectedCountry.name) {
        this.clientForm.country = this.selectedCountry.name;
      }

      this.clientForm.client = this.getClient.slug;

      piincHttp.post('partitions/', this.clientForm).then(function(response) {
        self.hideCreatePartitionModal();
        self.loadContent(self.currentPage);
        self.buttonDisabled = false;
        self.$toastr('success', self.$t('administration.partitions.create.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.buttonDisabled = false;
          self.error = error.data.message;
        }
      })
    },
    editPartitionFormSubmit(evt) {
      this.buttonDisabled = true;
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      evt.preventDefault();

      if (this.selectedCountry && this.selectedCountry.name) {
        this.clientForm.country = this.selectedCountry.name;
      }

      piincHttp.put('partitions/' + this.clientForm.slug, this.clientForm).then(function(response) {
        self.hideEditPartitionModal();
        self.loadContent(self.currentPage);
        self.$toastr('success', self.$t('administration.partitions.update.success'))
        self.buttonDisabled = false;
      }, function(error) {
        if (error.status !== 200) {
          self.buttonDisabled = false;
          self.error = error.data.message;
        }
      })
    },
    showCreatePartitionModal() {
      this.clientForm.name = '';
      this.clientForm.cvr = '';
      this.clientForm.street = '';
      this.clientForm.city = '';
      this.clientForm.postcode = '';
      this.clientForm.country = '';
      this.clientForm.reference_number = '';
      this.selectedCountry = [];

      this.$refs['create-partition'].show()
    },
    hideCreatePartitionModal() {
      this.$refs['create-partition'].hide()
    },
    showEditPartitionModal(object) {
      let self = this;

      this.clientForm.slug = object.slug;
      this.clientForm.name = object.name;
      this.clientForm.cvr = object.cvr;
      this.clientForm.street = object.street;
      this.clientForm.city = object.city;
      this.clientForm.reference_number = object.reference_number;
      this.clientForm.postcode = object.postcode;

      this.selectedCountry = [];
      if (object.country) {
        _.find(this.countries, function(item) {
          if (item.name === object.country) {
            self.selectedCountry = item;
          }
        });
      }

      this.$refs['edit-partition'].show()
    },
    hideEditPartitionModal() {
      this.$refs['edit-partition'].hide()
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-partition'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {};
      this.$refs['delete-partition'].hide()
    }

  },
  created () {
    this.loadCountries();
    this.loadGuides('partitions_list');
  }
}
</script>
